import {
  borderRadius,
  colors,
  controlBorderFocus,
  controlShadowFocus,
  primaryColor,
  problemColor,
  secondaryColor,
  spacing,
  stripeControlBorder,
  useSkin,
} from '@orus.eu/pharaoh'
import type { Appearance } from '@stripe/stripe-js'
import { useMemo } from 'react'

export function useStripeAppearance(): Appearance {
  const skin = useSkin()
  const { fontFamily } = skin.stripe

  return useMemo(
    () => ({
      theme: 'stripe',
      variables: {
        fontFamily: fontFamily,
        fontSizeBase: '16px',
        fontSizeSm: '14px',
        fontWeightNormal: '600',
        colorPrimary: primaryColor,
        colorText: primaryColor,
        colorTextSecondary: primaryColor,
        borderRadius: borderRadius[40],
        focusBoxShadow: controlShadowFocus,
        spacingGridColumn: spacing[60],
        spacingGridRow: spacing[60],
        colorIconTabSelected: colors.white,
      },
      rules: {
        '.Input': {
          border: stripeControlBorder,
          borderRadius: spacing[30],
          padding: `14px ${spacing[30]}`,
          height: '48px',
          fontWeight: '400',
        },
        '.Input:focus': {
          border: controlBorderFocus,
          outline: 'none',
          boxShadow: controlShadowFocus,
        },
        '.Input--invalid': {
          color: problemColor,
        },
        '.PaymentMethodSelector': {
          backgroundColor: colors.white,
          maxWidth: '400px',
        },
        '.Tab': {
          marginLeft: '16px',
          fontSize: '14px',
          fontWeight: '600',
          backgroundColor: colors.white,
          color: primaryColor,
          border: stripeControlBorder,
        },
        '.Tab:hover': {
          backgroundColor: colors.gray[200],
        },
        '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
          border: `1px solid ${secondaryColor}`,
          color: colors.white,
          backgroundColor: secondaryColor,
        },
        '.TermsText': {
          fontSize: '14px',
          fontWeight: '500',
          lineHeight: '20px',
          paragraphSpacing: '12px',
        },
        '.Label': {
          fontWeight: '500',
        },
        '.p-LinkAutofillPrompt': {
          fontWeight: '500',
        },
      },
      disableAnimations: true,
    }),
    [fontFamily],
  )
}
