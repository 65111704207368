import { useLanguage, useSkin } from '@orus.eu/pharaoh'
import type { Language } from '@orus.eu/translations'
import { loadStripe, type Stripe, type StripeElementsOptions } from '@stripe/stripe-js'
import { useMemo } from 'react'
import { trpc } from '../../client'
import { useStripeAppearance } from './appearance'

export function useStripeElementsProps(): { stripe: Promise<Stripe | null>; options: StripeElementsOptions } {
  const stripe = useStripePromise()
  const options = useStripeOptions()

  return { stripe, options }
}

function useStripePromise(): Promise<Stripe | null> {
  const language = useLanguage()
  return useMemo(() => initStripePromise(language), [language])
}

async function initStripePromise(language: Language) {
  const result = await trpc.paymentIntent.getStripePublicKey.query()
  const publicKey = result.publicKey
  return loadStripe(publicKey, { locale: language })
}

export function useStripeOptions(): StripeElementsOptions {
  const appearance = useStripeAppearance()
  const baseFontFamilyUrl = useSkin().stripe.fontFamilyUrl

  return useMemo<StripeElementsOptions>(
    () => ({
      mode: 'setup',
      paymentMethodCreation: 'manual',
      paymentMethodTypes: ['card'],
      appearance,
      fonts: [{ cssSrc: baseFontFamilyUrl }],
    }),
    [appearance, baseFontFamilyUrl],
  )
}
