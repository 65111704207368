import { TechnicalError } from '@orus.eu/error'
import { useAsyncCallback, useTranslate } from '@orus.eu/pharaoh'
import { isFailure } from '@orus.eu/result'
import type { Stripe, StripeElements } from '@stripe/stripe-js'
import type { FileRoutesByPath } from '@tanstack/react-router'
import { trpc } from '../../../client'

type AddStripePaymentMethodParams = {
  stripe: Stripe
  elements: StripeElements
  source:
    | { type: 'subscription'; subscriptionId: string }
    | {
        type: 'user'
        sessionLessAuth:
          | {
              token: string
              lastNameLetters: string
            }
          | undefined
      }
  setErrorMessage: (message: string) => void
  setLoading: (loading: boolean) => void
  handleSuccess: () => void
}

export function useAddStripePaymentMethod(): {
  addStripePaymentMethod: (params: AddStripePaymentMethodParams) => void
} {
  const translate = useTranslate()
  const unknownErrorMessage = translate('unknown_error')

  const addStripePaymentMethod = useAsyncCallback<[AddStripePaymentMethodParams]>(
    async ({ stripe, elements, source, setErrorMessage, handleSuccess, setLoading }) => {
      setLoading(true)

      const { error: submitError } = await elements.submit()
      if (submitError) {
        setLoading(false)
        setErrorMessage(submitError.message || unknownErrorMessage)
        return
      }

      const { error, confirmationToken } = await stripe.createConfirmationToken({
        elements,
        params: {
          return_url: AUTHENTICATION_RETURN_URL,
        },
      })

      if (error) {
        setLoading(false)
        setErrorMessage(error.message || unknownErrorMessage)
        return
      }

      if (!confirmationToken) {
        throw new TechnicalError('There should have been a confirmation token', {
          context: { source },
        })
      }

      const receiveConfirmationTokenResult = await (source.type === 'subscription'
        ? trpc.paymentMethod.receiveConfirmationTokenForSignature.mutate({
            subscriptionId: source.subscriptionId,
            confirmationTokenId: confirmationToken.id,
          })
        : trpc.paymentMethod.receiveConfirmationTokenForPaymentMethodUpdate.mutate({
            sessionLessAuth: source.sessionLessAuth,
            confirmationTokenId: confirmationToken.id,
          }))

      if (isFailure(receiveConfirmationTokenResult)) {
        switch (receiveConfirmationTokenResult.problem.type) {
          case 'invalid-card':
            setErrorMessage(
              translate('generic_card_payment_method_error', {
                reason: receiveConfirmationTokenResult.problem.message,
              }),
            )

            break
          case 'prepaid-card':
            setErrorMessage(translate('unaccepted_prepaid_card'))
            break
          case 'unsupported-type':
            setErrorMessage(translate('unsupported_payment_method'))
            break
        }

        setLoading(false)
        return
      }

      if (!receiveConfirmationTokenResult.output.requiresAuthentication) {
        handleSuccess()
        return
      }

      const { error: handleNextActionError, setupIntent } = await stripe.handleNextAction({
        clientSecret: receiveConfirmationTokenResult.output.clientSecret,
      })

      if (handleNextActionError) {
        setLoading(false)
        setErrorMessage(handleNextActionError.message || unknownErrorMessage)
        return
      }

      if (!setupIntent) {
        throw new TechnicalError('There should have been a setup intent', {
          context: { source },
        })
      }

      if (typeof setupIntent.payment_method !== 'string') {
        throw new TechnicalError('There should have been a payment method ID', {
          context: { source, setupIntentId: setupIntent.id },
        })
      }

      await (source.type === 'subscription'
        ? trpc.paymentMethod.receivePaymentMethodAfter3DSAuthenticationForSignature.mutate({
            subscriptionId: source.subscriptionId,
            paymentMethodId: setupIntent.payment_method,
          })
        : trpc.paymentMethod.receivePaymentMethodAfter3DSAuthenticationForPaymentMethodUpdate.mutate({
            sessionLessAuth: source.sessionLessAuth,
            paymentMethodId: setupIntent.payment_method,
          }))

      handleSuccess()
    },
    [translate, unknownErrorMessage],
  )

  return { addStripePaymentMethod }
}

const AUTHENTICATION_RETURN_PATH: keyof FileRoutesByPath = '/payment/authentication'
const AUTHENTICATION_RETURN_URL = new URL(AUTHENTICATION_RETURN_PATH, document.location.href).toString()
